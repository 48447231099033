<template>
    <div id="operationDetail" style="height:100%;">
        <v-expand-transition>
            <router-view/>
        </v-expand-transition>
    </div>
</template>
<script>
import refreshData from '@/mixins/mixins'
export default {
    name:'OperationTypeDetail',
    components: {

    },
    mixins:[refreshData],
    data() {
        return {
            // apiUrl: "/operation/get?id=" + this.$route.params.id,
            // mutation: "operations/setOperationsData"
        }
    },
    created() {
        //this.refreshData();
    },
    // destroyed() {
    //     this.$store.dispatch('base/SET_SUB_MENU', [])
    // },
    beforeRouteLeave (to, from, next) {
        this.$store.dispatch('base/SET_SUB_MENU', []);
        next();
    },
    methods: {

    },
    computed: {

    },
}
</script>
<style lang="scss">

</style>
