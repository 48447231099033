<template>
    <v-dialog width="600" v-model="openOpeDetailPopup" persistent>
        <v-card class="">
            <v-toolbar tile flat dark class="bg-gradient">
                <v-btn x-large depressed color="transparent" class="ml-2" dark @click.native="$emit('close')"><v-icon small class="mr-2" >$prev</v-icon> {{ $t('back') }}</v-btn>
                <v-spacer></v-spacer>
                <v-toolbar-title>{{$t('operationdetails')}}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn x-large depressed color="white" outlined class="ml-2" dark @click="getForm(editUrl)" v-if="$func.hasRight('operation/update')">{{ $t('modify') }}</v-btn>
            </v-toolbar>
            <v-card-text class="mt-6">
                <div class="block mt-6">
                    <div  v-if="operation.config && operation.type !== 'MARKETING' && operation.config.date_start && operation.config.date_end">
                        <v-icon small color="primary">$rocket_launch_d</v-icon>
                        <v-chip small class="ml-3 mr-6" color="secondary" label outlined>{{parseFullDateToFr(operation.config.date_start)}}</v-chip>

                        <v-icon small color="primary">$flag_checkered_d</v-icon>
                        <v-chip small class="ml-3" color="secondary" label outlined>{{parseFullDateToFr(operation.config.date_end)}}</v-chip>
                    </div>
                </div>

                <div class="block mt-5">
                    <div v-if="operation.name">
                        <div>{{$t('operationName')}} : <b>{{operation.name}}</b></div>
                    </div>
                    <div v-if="operation.reference">
                        <div>{{$t('reference')}} : <b>{{operation.reference}}</b></div>
                    </div>
                    <div v-if="getOperationDuration(operation)">
                        <div>{{$t('duration')}} : <b>{{getOperationDuration(operation)}}</b></div>
                    </div>
                    <div v-if="operation.config && operation.config.hasOwnProperty('modele_name')">
                        <div>{{$t('operationType')}} : <b>{{operation.config.modele_name}}</b></div>
                    </div>
                </div>

                <div class="block mt-2">
                    <div v-if="operation.config && operation['config.brand_ids']">
                        <div>{{$t('brands')}} : <b class="mr-2" :to="{name: 'BrandDetail', params: {brand_id: brand.id}}" v-for="(brand, index ) in operation['config.brand_ids']" :key="brand.id">{{brand.label}}<span v-if="operation['config.brand_ids'].length > 1 && index < (operation['config.brand_ids'].length - 1)">,</span></b></div>
                    </div>
                    <div v-if="operation.config && operation['config.univers_ids']">
                        <div>{{$t('univers')}} : <b class="mr-2" v-for="(univers, index) in operation['config.univers_ids']" :key="univers.id">{{univers.label}}<span v-if="operation['config.univers_ids'].length > 1 && index < (operation['config.univers_ids'].length - 1)">,</span></b></div>
                    </div>
                    <div v-if="entities">
                        <div>{{$t('entities')}} : 
                            <span v-if="this.$func.hasRight('entity/get')">
                                <router-link class="mr-2" :to="{name: 'EntityDetail', params: {entity_id: entity.id}}" v-for="entity in entities" :key="entity.id"><b class="link">{{entity.label}}</b></router-link>
                            </span>
                            <b v-else v-for="entity in entities" :key="entity.id">{{entity.label}}</b>
                        </div>
                    </div>
                    <div class="pb-2" v-if="operation.config && operation.config.segment_id">
                        <div>{{$t('segment')}} :<b>{{operation['config.segment_id'].label}}</b></div>
                    </div>
                    <div class="mt-2" v-if="intervenants && intervenants.length > 0">
                        <div>{{$t('intervenants')}} :
                            <b v-for="(intervenant, index) in intervenants" :key="intervenant.id">{{ intervenant.firstname }} {{ intervenant.name }}<span v-if="index < intervenants.length - 1">, </span></b>
                        </div>
                    </div>
                    <div class="mt-2" v-if="operation.created_by && operation.created_by.id">
                        <div>{{$t('createdBy')}} :
                            <router-link v-if="!operation.created_by.trash && this.$func.hasRight('user/get')" :to="{ name: 'UserDetail', params: { user_id: operation.created_by.id }}"><b class="link">{{ operation.created_by.firstname }} {{ operation.created_by.name }}</b></router-link>
                            <v-tooltip v-else-if="operation.created_by.trash" top>
                                <template v-slot:activator="{ on, attrs }">
                                    <span v-bind="attrs" v-on="on">
                                        {{ operation.created_by.firstname }} {{ operation.created_by.name }}
                                    </span>
                                </template>
                                <span>{{ $t('userDeleted') }}</span>
                            </v-tooltip>
                            <b v-else>{{ operation.created_by.firstname }} {{ operation.created_by.name }}</b>
                            <span v-if="operation.created_at"> {{$t('the')}} {{parseFullDateToFr(operation.created_at)}}</span>
                        </div>
                    </div>
                    <div v-if="operation.validated_by && operation.validated_by.id">
                        <div>{{$t('validatedBy')}} :
                            <router-link v-if="!operation.validated_by.trash && this.$func.hasRight('user/get')" :to="{ name: 'UserDetail', params: { user_id: operation.validated_by.id }}"><b class="link">{{ operation.validated_by.firstname }} {{ operation.validated_by.name }}</b></router-link>
                            <v-tooltip v-else-if="operation.validated_by.trash" top>
                                <template v-slot:activator="{ on, attrs }">
                                    <span v-bind="attrs" v-on="on">
                                        {{ operation.validated_by.firstname }} {{ operation.validated_by.name }}
                                    </span>
                                </template>
                                <span>{{ $t('userDeleted') }}</span>
                            </v-tooltip>
                            <b v-else>{{ operation.validated_by.firstname }} {{ operation.validated_by.name }}</b>
                            <span v-if="operation.validated_date"> {{$t('the')}} {{parseFullDateToFr(operation.validated_date)}}</span>
                        </div>
                    </div>
                    <div v-if="operation.closed_by && operation.closed_by.id">
                        <div>{{$t('closedBy')}} :
                            <router-link v-if="!operation.closed_by.trash && this.$func.hasRight('user/get')" :to="{ name: 'UserDetail', params: { user_id: operation.closed_by.id }}"><b class="link">{{ operation.closed_by.firstname }} {{ operation.closed_by.name }}</b></router-link>
                            <v-tooltip v-else-if="operation.closed_by.trash" top>
                                <template v-slot:activator="{ on, attrs }">
                                    <span v-bind="attrs" v-on="on">
                                        {{ operation.closed_by.firstname }} {{ operation.closed_by.name }}
                                    </span>
                                </template>
                                <span>{{ $t('userDeleted') }}</span>
                            </v-tooltip>
                            <b v-else>{{ operation.closed_by.firstname }} {{ operation.closed_by.name }}</b>
                            <span v-if="operation.closed_date"> {{$t('the')}} {{parseFullDateToFr(operation.closed_date)}}</span>
                        </div>
                    </div>
                    <div class="mt-4" v-if="operation.config && operation.config.comment">
                        {{$t('comment')}} :<p class="mb-0"><b>{{operation.config.comment}}</b></p>
                    </div>
                    <div v-if="operation.config && operation.config.files && operation.config.files.length > 0" class="attachementDiv mt-4">
                        {{$t('attachements')}} :
                        <div v-for="attachement in operation.config.files" :key="attachement.id" @click="downloadItem(attachement.url)" download outlined color="primary" >
                            <v-icon x-small color="lightgrey" class="mr-2">$circle_s</v-icon>
                            <span class="link" v-if="attachement.title">{{attachement.title}}</span>
                            <span class="link" v-else>{{attachement.filename.split('/').pop()}}</span>
                        </div>

                    </div>
                    <FormDialogComponent v-if="form" ref="componentForm" :type="'edit'" :form="form" :action="'SET_DATA'" @launchFunction="launchFunction"/>
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>
<script>
import dateManipulation from '@/mixins/dateManipulation';
import getForm from '@/mixins/mixins';
import GenericDataService from '@/services/GenericDataService';
import FormDialogComponent from "@/components/dialogs/FormDialogComponent";

export default {
    name: "detailOperationComponent",
    mixins:[dateManipulation, getForm],
    components: {FormDialogComponent},
    props: ["openOpeDetailPopup", "operation", "intervenants"],
    data() {
        return {
            form: null,
            editUrl: "operation/getForm?formName=edit&id=" + this.$route.params.operation_id,
            entities:[],
        }
    },
    created() {
        this.entities = []
        this.getEntities()
    },
    mounted() {
        this.entities = []
        this.getEntities()
    },
    watch: {
        operation:{
            handler(val){
                this.entities = []
                this.getEntities()
            },
            deep:true,
            immediate:true
        }
    },
    methods: {
        launchFunction($event){
            this[$event]();
        },
        getEntities() {
            if(this.operation && this.operation.config && this.operation.config.entity_id && this.operation.config.entity_id != ""){
                this.entities = this.operation['config.entity_id']
                // this.operation.config.entity_id.forEach(entityId => {
                //     if(this.$store.state.auth.currentUser.rights[0].entities.includes(entityId)){
                //         GenericDataService.getData('/entity/'+entityId+'/get').then((response) => {
                //             let findEntity = this.entities.find((e) => e.id == response.data.data.id)
                //             if(!findEntity){
                //                 this.entities.push(response.data.data)
                //             }
                //         })
                //     }
                   
                // });
            }
        },
        customRefresh() {
            this.$emit('refreshOperation');
        },
        getOperationDuration(operation) {
            if (operation.config && operation.config.date_start && operation.config.date_end) {
                let dateStart = new Date(operation.config.date_start);
                let dateEnd = new Date(operation.config.date_end);
                let diffTime = Math.abs(dateEnd - dateStart);
                let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
                return diffDays + " " + this.$t('days');
            }
            
            return false;
        }
    },
}
</script>
<style lang="scss">
    
</style>