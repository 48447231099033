<template>
    <div id="operationDetail" style="height:100%;">
        <v-expand-transition>
            <router-view :key="keyOperation"/>
        </v-expand-transition>
        <closeOperationDialog :openCloseOperationDialog="openCloseOpeForm" :operationId="this.$route.params.operation_id" @refresh="refresh" @close="openCloseOpeForm = false"/>
        <changeOperationDatesComponent v-if="openOpeDateChangeForm" :openChangeDateOperationDialog="openOpeDateChangeForm"  :operationId="this.$route.params.operation_id" @refresh="refresh" @close="openOpeDateChangeForm = false"/>
        <detailOperationComponent :openOpeDetailPopup="openOpeDetailPopup" :intervenants="intervenants" :operation="operation"  @close="openOpeDetailPopup = false" @refreshOperation="refresh"/>
    </div>
</template>
<script>
import refreshData from '@/mixins/mixins'
import closeOperationDialog from '../operationsComponents/closeOperationDialog.vue';
import changeOperationDatesComponent from '../operationsComponents/changeOperationDatesComponent.vue';
import detailOperationComponent from '../operationsComponents/detailOperationComponent.vue';
import GenericDataService from '@/services/GenericDataService'

export default {
    name:'OperationDetail',
    components: {
        closeOperationDialog,
        changeOperationDatesComponent,
        detailOperationComponent
    },
    mixins:[refreshData],
    data() {
        return {
            keyOperation: 0,
            apiUrl: "/operation/get?id=" + this.$route.params.operation_id,
            mutation: "operation/setOperationsData",
            intervenants: []
        }
    },
    created() {
        this.refreshData();
        GenericDataService.getData('/operation/getIntervenantsInformations?ope='+this.$route.params.operation_id).then(response => {
            if(response.data.data.intervenants){
                this.intervenants = response.data.data.intervenants
            }
        })
        // if(this.operation && this.operation.actions){
        //     let actions = this._.cloneDeep(this.operation.actions.get)
        //     actions.forEach(action => {
        //         if(action.status){
        //             if(action.status.compare == 'different'){
        //                 if( this.operation.status == action.status.value)
        //                     action.hide = true
        //             }
        //         }
        //     });
        //     this.$store.dispatch("base/SET_HEADER_ACTIONS", {});
        // }
    },
    // destroyed() {
    //     this.$store.dispatch('base/SET_SUB_MENU', [])
    // },
    beforeRouteLeave (to, from, next) {
        this.$store.dispatch('base/SET_SUB_MENU', []);
        this.$store.dispatch('base/SET_HEADER_ACTIONS', {});
        next();
    },
    methods: {
        refresh(){
            this.$store.dispatch("operation/SET_OPECLOSE_FORM", false);
            this.$store.dispatch("operation/SET_OPEDATECHANGE_FORM", false);
            this.refreshData();
            this.openOpeDetailPopup = false;
            this.keyOperation++;
        }
    },
    computed: {
        operation: function() {
            return this.$store.state.operation.operations;
        },
        openCloseOpeForm: {
            get: function(){
                return this.$store.state.operation.operationCloseForm;
            },
            set: function(val){
                this.$store.dispatch("operation/SET_OPECLOSE_FORM", false);
            }
        },
        openOpeDateChangeForm: {
            get: function(){
                return this.$store.state.operation.opeDateChangeForm;
            },
            set: function(val){
                this.$store.dispatch("operation/SET_OPEDATECHANGE_FORM", false);
            }
        },
        openOpeDetailPopup: {
            get: function(){
                return this.$store.state.operation.opeDetailPopup;
            },
            set: function(val){
                this.$store.dispatch("operation/SET_OPEDETAIL_POPUP", false);
            }
        }
    },
    watch: {
        $route(to, from) {
            this.apiUrl = "/operation/get?id=" + this.$route.params.operation_id;
            this.refreshData()
        },
        operation (newVal) {
            if(newVal.actions && newVal.actions.get && !this.$route.fullPath.includes('campaign') && !this.$route.fullPath.includes('contacts') && this.$route.name != 'OperationsDetailReport') {
                newVal.actions.get = JSON.parse(JSON.stringify(newVal.actions.get).replaceAll('{{id}}', this.operation.id));
                let actions = this._.cloneDeep(newVal.actions.get)
                actions.forEach(action => {
                    if(action.rules){
                        action.rules.forEach(rule => {
                            if(Array.isArray(rule.value)){
                                if(!rule.value.includes(newVal[rule.field]))
                                    action.hide = true
                            } else {
                                if(rule.value != newVal[rule.field])
                                    action.hide = true
                            }
                        });
                    }
                    if(action.hasOwnProperty('menus')){
                        let hiding = 0;
                        action.menus[0].forEach(subaction => {
                            if(subaction.rules){
                                subaction.rules.forEach(rule => {
                                    if(Array.isArray(rule.value)){
                                        if(!rule.value.includes(newVal[rule.field])){
                                            subaction.hide = true
                                        }
                                    } else {
                                        if(rule.value != newVal[rule.field]){
                                            subaction.hide = true
                                        }
                                    }
                                });
                                if(subaction.hide)
                                    hiding++
                            }
                        })
                        if(hiding == action.menus[0].length){
                            action.hide = true;
                        }
                    }
                });
                this.$store.dispatch("base/SET_HEADER_ACTIONS", actions);
            }
        }
    },
}
</script>
<style lang="scss">

</style>
