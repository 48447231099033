<template>
    <v-dialog width="800" v-model="openChangeDateOperationDialog" persistent>
        <v-card class="">
            <v-toolbar tile flat dark class="bg-gradient">
                <v-btn x-large depressed color="transparent" class="ml-2" dark @click.native="$emit('close')"><v-icon small class="mr-2" >$prev</v-icon> {{ $t('back') }}</v-btn>
                <v-spacer></v-spacer>
                <v-toolbar-title>{{$t('changeDateOperation')}}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text class="mt-6">
                <h5 class="text-center">{{ $t('whendoyouwanttomoveoperation') }}</h5>
                <v-row class="mt-2">
                    <v-col cols="12" class="pb-0">
                        <label class="label theme--light black--text"><b>{{$t('date_start')}}<span class="required"> *</span></b></label>
                        <vCustomDatePicker :model="model" :fieldName="'date_start'" :field="{props:{}}" />
                    </v-col>
                    <v-col cols="12" class="py-0">
                        <label class="label theme--light black--text"><b>{{$t('date_end')}}<span class="required"> *</span></b></label>
                        <vCustomDatePicker :model="model" :fieldName="'date_end'" :field="{props:{}}" />
                    </v-col>
                    <v-col cols="12" class="pt-0">
                        <h6>{{ $t('actiontobedetermined') }}</h6>
                        <span class="d-flex align-center mt-2">
                            <v-checkbox hide-details color="black" v-model="recalculateRetroplanning" :label="$t('recalculateRetroplanningDatesInRelationToNewOperationDates')" class="mt-0 pt-0 black--text" dense></v-checkbox>
                            <infoTooltip :tooltip="{ title: 'warningRecalculateRetroplanningDatesInRelationToNewOperationDates' }" />
                        </span>
                        <v-checkbox hide-details color="black" v-model="cancelRdv" :label="$t('cancelRdvOutsideOperationDates')" class="black--text" dense></v-checkbox>
                        <v-divider class="mt-4"></v-divider>
                        <v-checkbox hide-details color="black" v-model="impact" :label="$t('impactCheckBoxLabel')" class="mt-4 black--text" dense></v-checkbox>
                    </v-col>
                    <v-col cols="6">
                       <v-btn depressed color="error" :disabled="!impact" @click="changeDateOperation">{{$t('changeDateOperation')}}</v-btn>
                    </v-col>
                    <v-col cols="6" v-if="errorText != ''" style="align-items:center; display:flex;">
                        <b class="error--text">{{ $t(errorText) }}</b>
                    </v-col>
                </v-row>
                <v-dialog width="600" v-model="unavailableEntrypointDialog" persistent>
                    <v-card>
                        <v-toolbar tile flat dark class="bg-gradient">
                            <v-btn x-large depressed color="transparent" class="ml-2" dark @click.native="closeUnavailableEntrypointDialog"><v-icon small class="mr-2" >$prev</v-icon> {{ $t('back') }}</v-btn>
                            <v-spacer></v-spacer>
                            <v-toolbar-title>{{$t('entrypointsUnavailableToMove')}}</v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-spacer></v-spacer>
                        </v-toolbar>
                        <v-card-text>
                            <h5 class="text-center mt-6 mb-3">{{ $t('entrypointsunavailable') }} : {{ parseUsDateToFr(model.date_start)+"-"+parseUsDateToFr(model.date_end) }}</h5>
                            <v-row>
                                <v-col cols="12" v-for="(entrypoint, index) in entrypointUnavailable" :key="index">
                                    <v-row>
                                        <v-col cols="12" class="d-flex align-center">
                                            <v-btn class="no-shadow mr-2" depressed fab small color="lightgrey">
                                                <v-icon small color="primary" v-if="entrypoint.type == 'entryLanding' && entrypoint.config.subdomain && entrypoint.config.domain">$window_r</v-icon>
                                                <v-icon small color="primary" v-if="entrypoint.type == 'entryCallIn'">$phone_d</v-icon>
                                                <v-icon small color="primary" v-if="entrypoint.type == 'entryApi'">$displaycode_s</v-icon>
                                            </v-btn>
                                            <div v-if="entrypoint.type == 'entryLanding' && entrypoint.config.subdomain && entrypoint.config.domain">{{entrypoint.config.subdomain + '.' + entrypoint.config.domain}}</div>
                                            <div v-if="entrypoint.type == 'entryCallIn' && entrypoint.config.number">{{entrypoint.config.number}}</div>
                                            <div v-if="entrypoint.type == 'entryApi' && entrypoint.config.uniqId">
                                                <div v-if="entrypoint.config.name">{{entrypoint.config.name}}</div>
                                                <div>{{entrypoint.config.uniqId}}</div>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="12">
                                    {{ $t('changeDateOpeWithUnavailableEntrypointsText') }}
                                </v-col>
                                <v-col cols="12">
                                    <v-btn depressed color="error" @click="changeDateOperationAnyway">{{$t('changeDateAnywayOperation')}}</v-btn>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>   
                </v-dialog>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>
<script>
import vDatetimePicker from '@/components/forms/inputs/v-datetime-picker'
import vCustomDatePicker from '@/components/forms/inputs/v-date-picker.vue'
import dateManipulation from '@/mixins/dateManipulation'
import GenericDataService from '@/services/GenericDataService'
import infoTooltip from '@/components/infoTooltip';

export default {
    name: "changeOperationDatesComponent",
    props: ['openChangeDateOperationDialog', 'operationId'],
    mixins: [dateManipulation],
    components: {vDatetimePicker, vCustomDatePicker, infoTooltip},
    data() {
        return {
            impact: false,
            recalculateRetroplanning: false,
            cancelRdv: false,
            errorText: "",
            model:{
                date_start: "",
                date_end: ""
            },
            entrypointUnavailable: [],
            unavailableEntrypointDialog: false
        }
    },
    created() {
        if(this.operation.config.date_start)
            this.model.date_start = this.operation.config.date_start.split(' ')[0]
        if(this.operation.config.date_end)
            this.model.date_end = this.operation.config.date_end.split(' ')[0]
    },
    computed: {
        operation: function() {
            return this.$store.state.operation.operations;
        },
    },
    methods: {
        close(){
            this.errorText = ''
            this.$emit('close')
        },
        closeUnavailableEntrypointDialog(){
            this.unavailableEntrypointDialog = false
            this.entrypointUnavailable = [] 
        },
        changeDateOperation(){
            if(this.model.date_start == "" || this.model.date_end == ""){
                this.errorText = 'fieldsarerequired'
            } else if(this.model.date_start > this.model.date_end) {
                this.errorText = 'datestartmustbesuperior'
            } else {
                this.errorText = "";
                let payload = {
                    date_start: this.model.date_start,
                    date_end: this.model.date_end,
                    recalculateRetroplanning: this.recalculateRetroplanning,
                    cancelRdv: this.cancelRdv
                };
                GenericDataService.postData('operation/' + this.operationId + '/checkEntryPointBooking', payload).then((response) => {
                    if(response.data.data && response.data.data.available) { //le check des points d'entrées sont passés
                        GenericDataService.postData('operation/' + this.operationId + '/changeDate', payload).then((response) => {
                            if(response.data.data)
                                this.$emit('refresh');
                        })
                    } else {
                        //entrypoints non possible a bouger, on affiche la popup d'info
                        this.entrypointUnavailable = response.data.data.unavailableBooking
                        this.unavailableEntrypointDialog = true
                    }
                })
            }
        },
        changeDateOperationAnyway() {
            let payload = {
                date_start: this.model.date_start,
                date_end: this.model.date_end,
                recalculateRetroplanning: this.recalculateRetroplanning,
                cancelRdv: this.cancelRdv
            };
            GenericDataService.postData('operation/' + this.operationId + '/changeDate', payload).then((response) => {
                if(response.data.data)
                    this.$emit('refresh');
            })
        }
    },
}
</script>