<template>
    <v-dialog width="600" v-model="openCloseOperationDialog" persistent>
        <v-card class="closeoperationdialog">
            <v-toolbar tile flat dark class="bg-gradient">
                <v-btn x-large depressed color="transparent" class="ml-2" dark @click.native="$emit('close')"><v-icon
                        small class="mr-2">$prev</v-icon> {{ $t('back') }}</v-btn>
                <v-spacer></v-spacer>
                <v-toolbar-title>{{ $t('closeOperationConfirmation') }}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
                <v-btn ddepressed outlined color="white"
                    :disabled="!impact || (!closedReasonField.value && packId != 2)" @click="closeOperation">{{
                        $t('closeOperation') }}</v-btn>

            </v-toolbar>
            <v-card-text class="mt-6">
                <h5 class="text-center">{{ $t('doyouwanttocloseoperation') }}</h5>
                <v-row class="mt-1">
                    <v-col cols="12">
                        <v-alert class="mt-0 mb-0" border="left" color="error" text>
                            <b>{{ $t('warningactionnotreversible') }}</b>
                        </v-alert>
                    </v-col>
                    <v-col cols="12" class="pt-0" v-if="todoNumber > 0">
                        <v-alert @click="goToTodo" class="mt-0 mb-0" style="cursor:pointer;" border="left"
                            color="warning" text>
                            <b style="text-decoration:underline">{{ $t('warningthereisactiontobedone', {
                                nb:
                                    todoNumber
                            }) }}</b>
                        </v-alert>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <h6>{{ $t('actionthatLexxywilldo') }}</h6>
                        <ul class="black--text">
                            <li v-if="packId != 2">{{ $t('rdvswillbesetincancel') }}</li>
                            <li v-if="packId != 2">{{ $t('demandwillbesetinfail') }}</li>
                            <li>{{ $t('cancelprogrammedcampaign') }}</li>
                            <li>{{ $t('freebookedentrypoint') }}</li>
                            <li>{{ $t('deactivateworkflows') }}</li>
                            <li>{{ $t('removewaitingcontactinworkflows') }}</li>
                            <li v-if="packId != 2">{{ $t('blockcreationofleadscampaignorworkflow') }}</li>
                        </ul>
                        <VCustomSelect class="mt-4" v-if="packId != 2" :model="closedReasonField" :fieldName="'value'"
                            :field="closedReasonField" />
                        <v-checkbox hide-details color="black" v-model="impact" :label="$t('impactCheckBoxLabel')"
                            class="mt-4 black--text" dense></v-checkbox>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>
<script>
import GenericDataService from '@/services/GenericDataService';
import VCustomSelect from "@/components/forms/inputs/v-custom-select";

export default {
    name: "closeOperationDialog",
    props: ['openCloseOperationDialog', 'operationId'],
    components: { VCustomSelect },
    data() {
        return {
            todoNumber: 0,
            impact: false,
            closedReasonField: {
                title: 'superleadDemandEchecMotif',
                props: { dense: true, solo: true, flat: true, required: true, 'hide-details': true },
                selectDataUrl: "/custom_value/getSelectListForType?type=cancel_reason",
                value: null
            },
        }
    },
    computed: {
        packId() {
            if (this.$store.state.auth.currentUser.accountParameters.packId)
                return this.$store.state.auth.currentUser.accountParameters.packId
            else
                return false
        },
    },
    methods: {
        closeOperation() {
            GenericDataService.postData('/operation/' + this.operationId + '/closeOperation?closed_reason=' + this.closedReasonField.value).then((response) => {
                this.$emit('refresh')
            })
        },
        goToTodo() {
            this.$router.push({ name: "TodosList", query: { "datatableFilters": "{\"operation\":[\"" + this.operationId + "\"]}" } })
            this.$emit('close')
        }
    },
    watch: {
        openCloseOperationDialog(val) {
            if (val) {
                GenericDataService.getData('/operation/countTodosOnOperation?operationId=' + this.operationId).then((response) => {
                    this.todoNumber = response.data.data;
                })
            } else {
                this.impact = false;
                this.closedReasonField.value = null;
            }
        }
    },
}
</script>
<style lang="scss">
.closeoperationdialog {
    .theme--light.v-label {
        color: #000 !important;
    }

    ul {
        list-style: none;

        li {
            color: #000;
            position: relative;
            margin-top: 3px;
            font-size: 14px;

            &::before {
                content: "•";
                left: -20px;
                top: 0px;
                font-size: 30px;
                position: absolute;
                color: var(--v-primary-base);
            }
        }
    }
}
</style>